$green: #e0f970;
.container__about {
  height: 100vh;
  width: auto;
  background: url("../../../public/assets/meteor.png");
  background-color: rgba(0, 0, 0, 0.95);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &-title {
    width: 100%;
    display: flex;
    align-items: center;
    h2 {
      margin-block-end: 0;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      color: #e0f970;
      @media only screen and (min-width: 1024px) {
        font-weight: 500;
        font-size: 20px;
      }
    }
    .line {
      height: 1px;
      width: 7%;
      background-color: $green;
      margin-right: 2%;
    }
  }
  .box-about {
    strong {
      color: #e0f970;
      height: 30%;
    }
    &-left {
      text-align: justify;
      border-left: 1px solid $green;
      padding-left: 30px;
      padding-right: 30px;
    }
    &-right {
      border-right: 1px solid $green;
      padding-right: 30px;
      padding-left: 30px;
      text-align: justify;
    }
    @media only screen and (min-width: 768px) {
      width: 60%;
      margin-left: 7%;
      margin-right: 7%;
      &-right {
        align-self: flex-end;
      }
      &-left {
        align-self: flex-start;
      }
    }
    @media only screen and (min-width: 1024px) {
      width: 50%;
      p {
        font-weight: 400;
        font-size: 22px;
      }
    }
  }
}
