@font-face {
  font-family: "Coolvetica";
  src: url("./fonts/coolvetica/coolvetica\ rg.otf") format("opentype");

  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format(("truetype"));
  src: url("./fonts/Poppins/Poppins-Bold.ttf") format(("truetype"));
  src: url("./fonts/Poppins/Poppins-Light.ttf") format(("truetype"));
}
* {
  font-family: "Poppins", "Coolvetica", Arial, Helvetica, sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
p {
  color: white;
}
a {
  text-decoration: none;
}
h1 {
  font-family: "Coolvetica", Arial, Helvetica, sans-serif;
}
h2 {
  font-weight: 700;
  font-size: 20px;
}

@media only screen and (min-width: 1024px) {
  h2 {
    font-size: 40px !important;
  }
}
