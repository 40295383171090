$green: #e0f970;
.container__header {
  z-index: 5;
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0.9) 0%,
    rgba(30, 30, 30, 0) 100%
  );
  @media only screen and (min-width: 1200px) {
    justify-content: space-around;
  }
  &-burger {
    z-index: 7;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
    .nav-icon {
      display: flex;
      flex-direction: column;
      width: 35px;
      transform: rotate(0deg);
      transition: 0.2s ease-in-out;
      cursor: pointer;
      row-gap: 7px;
      z-index: 6;

      span {
        height: 2px;
        width: 100%;
        background: white;
        border-radius: 9px;
      }
    }

    .nav-icon.open {
      row-gap: 0;
      justify-content: center;
      align-content: center;
    }
    .nav-icon.open span:nth-child(1) {
      position: relative;
      background-color: $green;
      rotate: 45deg;
      transform: translate(50% 50%);
      top: 1px;
    }
    .nav-icon.open span:nth-child(2) {
      display: none;
    }
    .nav-icon.open span:nth-child(3) {
      background-color: $green;
      position: relative;
      rotate: -45deg;
      top: -1px;
    }
  }
  &-btn {
    a {
      font-weight: 700;
      font-size: 10px;
      width: 70%;
      color: $green;
      border: 2px solid $green;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.1);
      padding: 3px 15px;
    }
    @media only screen and (min-width: 768px) {
      width: auto;
      a {
        font-size: 18px;
      }
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      width: 70px;
      height: auto;
    }
    h1 {
      font-weight: 400;
      font-size: 14px;
    }
    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      column-gap: 10px;
      width: auto;
      img {
        width: 100px;
      }
      h1 {
        font-size: 26px;
      }
    }
  }
  &-nav {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
    display: flex;
    justify-content: space-evenly;
    column-gap: 10px;
    a {
      font-weight: 300;
      font-size: 20px;
      color: white;
    }
  }
}
