$green: #e0f970;
.section__home {
  height: 800px;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50px;
  overflow: hidden;
  video {
    z-index: -1;
  }
  h2 {
    z-index: 1;
    top: -70vh;
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin: 1vh;
    strong {
      color: $green;
      top: -70vh;
    }
  }

  .chevron {
    color: $green;
    position: relative;
    z-index: 1;
    top: -70vh;
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 50px;
    }
    .chevron {
      font-size: 3em;
    }
    a {
      display: inline-block;
    }
  }
}
