$green: #e0f970;
.container__mission {
  width: auto;
  background: url("../../../public/assets/bg-mission.png");
  background-color: rgba(0, 0, 0, 0.95);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &-title {
    width: 100%;
    display: flex;
    align-items: center;

    .line {
      height: 1px;
      width: 7%;
      background-color: $green;
      margin-right: 2%;
    }
  }
  &-subtitle {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    strong {
      color: $green;
    }
  }
  @media only screen and (min-width: 1024px) {
    p {
      font-weight: 400;
      font-size: 22px;
    }
    h3 {
      font-weight: 700;
      font-size: 40px;
    }
  }
}
