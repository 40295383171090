$green: #e0f970;
.container__carouselCoreTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  .chevron {
    color: $green;
    cursor: pointer;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.container__carouselCoreTeam-xl {
  display: flex;
  justify-content: center;
  align-items: baseline;
  .chevron {
    color: $green;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
