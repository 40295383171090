.cardCoreTeam {
  width: 70%;
  display: flex;
  flex-direction: column;
  a {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 80%;
    align-self: center;
    object-fit: contain;
    border-radius: 5px;
    z-index: 1;
    // box-shadow: 0px 2px 13px 8px #2c2c2c7d;
  }
  span {
    width: 80%;
    align-self: center;
    font-weight: 700;
    font-size: 16px;
    color: white;
  }

  &-info {
    opacity: 0;
  }
  &:hover {
    img {
      opacity: 0.2;
    }
  }
  :hover ~ &-info {
    display: flex;
    opacity: 1;
    justify-content: center;
    z-index: 0;
    position: relative;
    top: -200px;
  }

  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  @media only screen and (min-width: 1024px) {
    width: 30%;
    span {
      font-weight: 700;
      font-size: 24px;
    }
  }
}
