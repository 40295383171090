$green: #e0f970;
.container__investors {
  background-color: #131313;
  width: auto;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  row-gap: 20px;

  &-title {
    width: 100%;
    display: flex;
    align-items: center;

    .line {
      height: 1px;
      width: 7%;
      background-color: $green;
      margin-right: 2%;
      margin-block: 0;
    }
    h2 {
      margin-block: 0;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      color: white;
      display: inline-block;
      width: 80%;
      margin-bottom: 30px;
      @media only screen and (min-width: 1024px) {
        font-weight: 400;
        font-size: 25px;
      }
    }
  }
  &-subtitle {
    color: white;
    margin-left: 9%;
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 14px;
    @media only screen and (min-width: 1024px) {
      font-weight: 400;
      font-size: 18px;
    }
  }
}
