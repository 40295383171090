$green: #e0f970;
.container__coreTeam {
  display: flex;
  flex-direction: column;
  background: url("../../../public/assets/meteor.png");
  background-color: rgba(0, 0, 0, 0.95);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 30px 20px;
  &-subtitle {
    display: flex;
    justify-content: center;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    width: 70%;
    strong {
      color: $green;
      font-weight: 500;
      font-size: 14px;
    }
    @media only screen and (min-width: 1024px) {
      justify-content: flex-start;
      margin-left: 9%;
      width: 30%;
    }
  }
}
