$green: #e0f970;

form {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
    width: 50%;
    align-self: flex-start;
    margin-left: 7%;
  }

  input,
  textarea {
    background: rgba(45, 45, 45, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    border-style: none;
    resize: none;
    color: white;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    height: 35px;
  }
  textarea {
    height: 150px;
  }

  .submit-btn {
    font-weight: 700;
    font-size: 22px;
    border: 3px solid $green;
    color: $green;
    border-radius: 100px;
    align-self: flex-end;
    width: 40%;
    background-color: transparent;
    padding: none !important;
    cursor: pointer;

    @media only screen and (max-width: 390px) {
      font-size: 16px;
    }
  }
}
.form-thanks {
  display: flex;
  justify-content: center;
  background-color: rgba(45, 45, 45, 0.6);
  width: 90%;
  align-self: center;
  text-align: center;
  border: 2px solid $green;
  //   border-radius: 50px;
  font-size: 24px !important;
  font-weight: 700;
  color: $green;
  padding: 10px;
  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
}
