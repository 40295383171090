$green: #e0f970;
.container__carouselInvestors {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  img {
    border-radius: 50%;
    height: 30vh;
    width: 30vh;
    object-fit: contain;
  }
  .chevron {
    color: $green;
    cursor: pointer;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.container__carouselInvestors-xl {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-evenly;

  row-gap: 20px;
  img {
    border-radius: 50%;
    display: block;
    width: 35vh;
    height: 35vh;
    object-fit: contain;
    padding: 10px;
  }
  .chevron {
    color: $green;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.box-investor {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: 10px;
    color: $green;
    font-weight: 700;
    font-size: 16px;
  }
}
