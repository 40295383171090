$green: #e0f970;
.container__contact {
  height: 100vh;
  width: auto;
  background: url("../../../public/assets/meteor.png");
  background-color: rgba(0, 0, 0, 0.95);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &-title {
    width: 100%;
    display: flex;
    align-items: center;
    .line {
      height: 1px;
      width: 7%;
      background-color: $green;
      margin-right: 2%;
    }
    h2 {
      margin-block: 0;
    }
    h3 {
      font-weight: 700;
      font-size: 16px;
      color: $green;
      margin-block: 0;
      @media only screen and (min-width: 1024px) {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  &-network {
    z-index: 2;
    position: relative;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
  }
}
