$green: #e0f970;
.box-rookies {
  display: flex;
  flex-direction: column;
  width: 60%;
  color: white;
  img {
    width: 100%;
    height: 230px;
    max-height: 350px;
    object-fit: contain;
    @media only screen and (min-width: 768px) {
      height: 250px;
    }
  }
  span {
    position: relative;
    top: -35px;
    left: 15px;
    font-weight: 700;
    font-size: 16px;
    text-shadow: 0px 0px 2px #406f0af0;
  }
  h4 {
    margin-block: 0;
    color: $green;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    height: 100px;
    overflow: auto;
  }
  a {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $green;
    padding: 10px 20px;
    border: 1px solid $green;
    width: 40%;
    border-radius: 30px;
    @media only screen and (min-width: 768px) {
      width: 25%;
    }
  }
}
