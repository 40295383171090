$green: #e0f970;
.container__carouselRookies {
  display: flex;
  justify-content: space-around;
  align-items: center;
  // column-gap: 20px;
  img {
    height: 200px;
    width: 200px;
    object-fit: contain;
  }
  .chevron {
    color: $green;
    cursor: pointer;
    top: -100px;
    position: relative;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.container__carouselRookies-xl {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 7%;
  row-gap: 30px;
  article {
    width: 30%;
  }
  img {
    object-fit: contain;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
